
<template>
      <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="用户列表"  >
      <ChannelUser1></ChannelUser1>


      </a-tab-pane>
      <a-tab-pane key="2" tab="静默用户(7日)">
     <ChannelUser2></ChannelUser2>

      </a-tab-pane>

    </a-tabs>
</template>

<script>
 import ChannelUser1 from "./ChannelUser1.vue";
 import ChannelUser2 from "./ChannelUser2.vue";
export default {
  components: {
   ChannelUser1,
   ChannelUser2
  },
  data() {
   return{}
  },


 

};
</script>

